exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cn-china-market-entry-js": () => import("./../../../src/pages/cn/china-market-entry.js" /* webpackChunkName: "component---src-pages-cn-china-market-entry-js" */),
  "component---src-pages-cn-company-liquidation-js": () => import("./../../../src/pages/cn/company-liquidation.js" /* webpackChunkName: "component---src-pages-cn-company-liquidation-js" */),
  "component---src-pages-cn-company-registration-js": () => import("./../../../src/pages/cn/company-registration.js" /* webpackChunkName: "component---src-pages-cn-company-registration-js" */),
  "component---src-pages-cn-company-restructure-js": () => import("./../../../src/pages/cn/company-restructure.js" /* webpackChunkName: "component---src-pages-cn-company-restructure-js" */),
  "component---src-pages-cn-employer-of-record-js": () => import("./../../../src/pages/cn/employer-of-record.js" /* webpackChunkName: "component---src-pages-cn-employer-of-record-js" */),
  "component---src-pages-cn-index-js": () => import("./../../../src/pages/cn/index.js" /* webpackChunkName: "component---src-pages-cn-index-js" */),
  "component---src-pages-cn-payroll-outsourcing-services-china-js": () => import("./../../../src/pages/cn/payroll-outsourcing-services-china.js" /* webpackChunkName: "component---src-pages-cn-payroll-outsourcing-services-china-js" */),
  "component---src-pages-cn-profit-repatriation-js": () => import("./../../../src/pages/cn/profit-repatriation.js" /* webpackChunkName: "component---src-pages-cn-profit-repatriation-js" */),
  "component---src-pages-editorial-policy-js": () => import("./../../../src/pages/editorial-policy.js" /* webpackChunkName: "component---src-pages-editorial-policy-js" */),
  "component---src-pages-founders-diary-js": () => import("./../../../src/pages/founders-diary.js" /* webpackChunkName: "component---src-pages-founders-diary-js" */),
  "component---src-pages-getstarted-js": () => import("./../../../src/pages/getstarted.js" /* webpackChunkName: "component---src-pages-getstarted-js" */),
  "component---src-pages-hk-accounting-services-hong-kong-company-audit-js": () => import("./../../../src/pages/hk/accounting-services-hong-kong/company-audit.js" /* webpackChunkName: "component---src-pages-hk-accounting-services-hong-kong-company-audit-js" */),
  "component---src-pages-hk-accounting-services-hong-kong-index-js": () => import("./../../../src/pages/hk/accounting-services-hong-kong/index.js" /* webpackChunkName: "component---src-pages-hk-accounting-services-hong-kong-index-js" */),
  "component---src-pages-hk-bank-account-opening-js": () => import("./../../../src/pages/hk/bank-account-opening.js" /* webpackChunkName: "component---src-pages-hk-bank-account-opening-js" */),
  "component---src-pages-hk-change-company-secretary-js": () => import("./../../../src/pages/hk/change-company-secretary.js" /* webpackChunkName: "component---src-pages-hk-change-company-secretary-js" */),
  "component---src-pages-hk-company-registration-js": () => import("./../../../src/pages/hk/company-registration.js" /* webpackChunkName: "component---src-pages-hk-company-registration-js" */),
  "component---src-pages-hk-company-secretary-js": () => import("./../../../src/pages/hk/company-secretary.js" /* webpackChunkName: "component---src-pages-hk-company-secretary-js" */),
  "component---src-pages-hk-index-js": () => import("./../../../src/pages/hk/index.js" /* webpackChunkName: "component---src-pages-hk-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ob-2025-js": () => import("./../../../src/pages/ob2025.js" /* webpackChunkName: "component---src-pages-ob-2025-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-payment-guidelines-js": () => import("./../../../src/pages/payment-guidelines.js" /* webpackChunkName: "component---src-pages-payment-guidelines-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-switch-js": () => import("./../../../src/pages/switch.js" /* webpackChunkName: "component---src-pages-switch-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-video-guides-js": () => import("./../../../src/pages/video-guides.js" /* webpackChunkName: "component---src-pages-video-guides-js" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-video-guide-js": () => import("./../../../src/templates/video-guide.js" /* webpackChunkName: "component---src-templates-video-guide-js" */)
}

